import Swiper, { Navigation, Keyboard, EffectFade } from "Parent/js/modules/swiper/swiper";

Swiper.use([Navigation, Keyboard, EffectFade]);

class Sliders {
	constructor() {

        this.home_slider_el = document.querySelector(".events-slider");
		this.home_slider = null;

		if (this.home_slider_el) {
			this.runHomeSlider();
		}

		this.visit_slider_el = document.querySelector(".visit-slider");
		this.visit_slider = null;

		if (this.visit_slider_el) {
			this.runVisitSlider();
		}

		this.hero_slider_el = document.querySelector(".hero-slider");
		this.hero_slider = null;

		if (this.hero_slider_el) {
			this.runHeroSlider();
		}

		
		this.news_slider_el = document.querySelector(".news-slider");
		this.news_slider = null;

		if (this.news_slider_el) {
			this.runNewsSlider();
		}

	}

	runHomeSlider() {
		this.home_slider = new Swiper(this.home_slider_el, {
            loop: true,
            loopedSlides: 20,
            slidesPerView: "auto",
            spaceBetween: 30,
            navigation: {
				nextEl: ".events-slider .swiper-right",
                prevEl: ".events-slider .swiper-left"
			},

		});
	}

	runVisitSlider() {
		this.visit_slider = new Swiper(this.visit_slider_el, {
            loop: true,
            loopedSlides: 20,
            slidesPerView: "auto",
            spaceBetween: 10,
            navigation: {
				nextEl: ".visit-slider .swiper-right",
                prevEl: ".visit-slider .swiper-left"
			},

		});
	}

	runHeroSlider() {
		this.visit_slider = new Swiper(this.hero_slider_el, {
			loop: false,
			loopedSlides: 1,
			slidesPerView: 1,
			effect: 'fade', // Enabling the fade effect
			EffectFade: {
			  crossFade: true, // Enabling crossFade for smooth transition
			},
			navigation: {
			  nextEl: ".hero-slider .swiper-right",
			  prevEl: ".hero-slider .swiper-left"
			},
			on: {
			  init: function() {
				console.log('Swiper initialized');
			  },
			  slideChange: function() {
				console.log('Slide changed');
			  },
			},
		  });
	}

	runNewsSlider() {
		this.visit_slider = new Swiper(this.news_slider_el, {
            loop: true,
            loopedSlides: 10,
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
				nextEl: ".news-slider .swiper-right",
                prevEl: ".news-slider .swiper-left"
			},
			breakpoints: {
				// when window width is >= 320px
				575: {
				  slidesPerView: 1.6,
				},
			  }
		});
	}
}

export default Sliders;