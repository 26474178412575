class Globals {

    constructor() {

        const closeMenu = document.getElementById('closeMenu');
        const menuTrigger = document.getElementById('toggleMenu');
        const mainMenu = document.getElementById('mainMenu');
        const topLevelItems = document.querySelectorAll('li.menu-item-has-children');
        const closeSearch = document.getElementById('closeSearch');
        const openSearch = document.getElementById('searchButton');

        //Attach event listener to closeMenu.
        closeMenu.addEventListener("click", e => {
            this.closeMenu();
        });

        //Attach event listener to closeSearch.
        closeSearch.addEventListener("click", e => {
            this.closeSearch();
        });

        //Attach event listener to openSearch.
        openSearch.addEventListener("click", e => {
            this.closeMenu();
            this.openSearch();
        });

        //Attach event listener to open.
        menuTrigger.addEventListener("click", e => {
            mainMenu.style.opacity = '1';

            setTimeout(this.openMenu(), 300);
        });

        topLevelItems.forEach((item) => {
            let menuButton = document.createElement('button');
            menuButton.classList.add('toggleMenuItem');
            menuButton.setAttribute('aria-label', 'Toggle Sub Menu');

            item.appendChild(menuButton);

            menuButton.addEventListener("click", e => {

                const activeLi = document.querySelector('#mainMenu li.active');
                const activeButton = document.querySelector('#mainMenu button.active');

                if(activeLi) {
                    activeLi.classList.remove('active');
                }
                if(activeButton) {
                    activeButton.classList.remove('active');
                }

                console.log(activeLi);
                console.log(e);
                if(activeButton !== e.target) {
                    e.target.parentElement.classList.toggle('active');
                    e.target.classList.toggle('active');
                }
            });

        });


    }

    openMenu() {
        const body = document.querySelector('body');

        body.classList.add('active');
    }

    closeMenu() {
        const body = document.querySelector('body');
        body.classList.remove('active');

        setTimeout(() => {
            mainMenu.style.opacity = '0';
        }, 300);
    }

    openSearch() {
        const body = document.querySelector('body');

        body.classList.add('search-active');
    }

    closeSearch() {
        const body = document.querySelector('body');

        body.classList.remove('search-active');
    }
}

export default Globals;
